/**
 * 主体布局
 */
import cls from 'classnames';
import React, { useContext, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';

import {
  useUserInfo,
  useProductKinds,
  useThemeConfig,
  useCurrencyConfig,
} from '@/stores';
import type { Mode } from '@/stores';

import { Header } from '../Header';
import { Menu } from '../Menu';
// import { Notice } from '../Notice';
import { Footer } from '../Footer';
import globalContext from '@/configs/globalContext';
import HeaderSearch from '../HeaderSearch';
import styles from './index.module.scss';
import { useLocation } from 'react-router-dom';

export interface MainLayoutProps {
  children: React.ReactNode;
}

const ThemeStyles: Record<Mode, Record<string, any>> = {
  normal: {},
  /** 活动页面配置 */
  activity: {
    '--footer-color': '#fff',
    '--footer-bg': '#007697',
  },
};

export const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  const { isMobile } = useContext(globalContext);
  const mode = useThemeConfig((state) => state.mode);

  const { getLoginUserInfo } = useUserInfo();
  const { getHomeProductKindList } = useProductKinds();
  const { getRateList } = useCurrencyConfig();
  useQuery([getRateList.name], getRateList, {
    suspense: true,
  });
  useQuery([getLoginUserInfo.name], getLoginUserInfo);
  useQuery([getHomeProductKindList.name], getHomeProductKindList);
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  }, [location]);
  return (
    <div className={styles.wrap} style={ThemeStyles[mode] ?? {}}>
      <Header />
      <div className={styles.sticky}>
        {isMobile ? (
          <div className={styles.search}>
            <HeaderSearch />
          </div>
        ) : (
          <Menu />
        )}
      </div>
      <main className={cls(styles.main)}>{children}</main>
      <Footer />
    </div>
  );
};
