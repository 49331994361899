import create from 'zustand';
import { getCurrencyRateList } from '@/apis';
import { type InferActionRes, type InferArrayValue } from '@/types/infer';

type CurrencyRateType = InferArrayValue<
  NonNullable<
    NonNullable<InferActionRes<typeof getCurrencyRateList>['data']>['list']
  >
>;

export interface CurrencyConfig {
  currency: string;
  currencyRate: CurrencyRateType;
  rateList: CurrencyRateType[];

  setCurrent: (currency: string) => void;
  getRateList: () => Promise<CurrencyRateType[]>;
}

export const CURRENCY_KEY = 'hexapus-mal-currency';

export const useCurrencyConfig = create<CurrencyConfig>((set, get) => ({
  currency: window.localStorage.getItem(CURRENCY_KEY) ?? 'USD',
  currencyRate: {
    to_currency: 'USD',
    exchange_rate: 1,
  },
  rateList: [
    {
      to_currency: 'USD',
      exchange_rate: 1,
    },
  ],

  setCurrent(currency) {
    const { rateList } = get();

    window.localStorage.setItem(CURRENCY_KEY, currency);
    set({
      currency: currency,
      currencyRate: rateList.find((rate) => rate.to_currency === currency),
    });
    window.location.reload();
  },

  async getRateList() {
    const { currency } = get();
    const res = await getCurrencyRateList({});
    const list = res.data?.list ?? [];
    list.push({
      to_currency: 'USD',
      exchange_rate: 1,
    });
    set({
      rateList: list,
      currencyRate: list.find((rate) => rate.to_currency === currency),
    });
    return list;
  },
}));
