import React, { memo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Empty } from 'antd';
import { useCarts } from '@/stores';
import styles from './index.module.scss';
import CartItem from './CartItem';
import { PayChannel, PayChannelFormatorMap } from '@/configs/constants';

const priceFormatter = PayChannelFormatorMap.get(PayChannel.Cash)!;

const HeaderCart: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { cartList, cartTotal, cartPrice, createCartOrder, setOpen } =
    useCarts();

  const checkout = async () => {
    setLoading(true);
    try {
      const id = await createCartOrder({
        goods_list: cartList.map((item) => ({
          goods_id: item.id || 0,
          num: item.num || 0,
        })),
      });
      setLoading(false);
      if (!!id) {
        navigate(`order/cart/${id}`);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  return (
    <div className={styles.wrap}>
      {cartList && cartList.length > 0 && (
        <>
          <div className={styles.list}>
            {cartList.map((item) => (
              <CartItem key={`${item.id}${item.num}`} info={item} />
            ))}
          </div>
          <p className={styles.text}>
            <span>
              Subtotal <span className={styles.num}>{cartTotal}</span>
            </span>
            <span className={styles.price}>{priceFormatter(cartPrice)}</span>
          </p>
          <Button.Group className={styles.btns}>
            <Button
              onClick={() => {
                navigate('/cart');
                setOpen(false);
              }}
              className={styles.btn}
              type="primary"
            >
              View Cart
            </Button>
            <Button
              onClick={() => checkout()}
              loading={loading}
              className={styles.btn}
              type={'second' as any}
            >
              Checkout
            </Button>
          </Button.Group>
        </>
      )}
      {cartList && cartList.length === 0 && <Empty />}
    </div>
  );
};

export default memo(HeaderCart);
