import React, { useEffect, useMemo } from 'react';
import { Select, type SelectProps } from 'antd';
import { useCurrencyConfig } from '@/stores';
import { CurrencyIconMap } from '@/configs/constants/pay';

import styles from './index.module.scss';

export const CurrencySelect: React.FC<SelectProps> = (props) => {
  const { rateList, setCurrent, getRateList, currency } = useCurrencyConfig();
  const options = useMemo<SelectProps['options']>(
    () =>
      rateList.map((rate) => ({
        value: rate.to_currency!,
        label: (
          <>
            <img
              className={styles.currencyIcon}
              src={CurrencyIconMap.get(rate.to_currency!)}
              alt={rate.to_currency}
            />
            <span className={styles.currencyText}>{rate.to_currency}</span>
          </>
        ),
      })),
    [rateList],
  );

  useEffect(() => {
    getRateList();
  }, [getRateList]);

  return (
    <Select
      {...props}
      className={styles.currency}
      value={currency}
      options={options}
      allowClear={false}
      showArrow={false}
      onChange={(currency) => setCurrent(currency)}
    />
  );
};
